.login_wrapper {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login_wrapper video {
    width: 100%;
    position: fixed;
    z-index: 0;
    filter: blur(10px);
}

.login_container {
    z-index: 1;
    width: 800px;
    height: 500px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px;
    box-sizing: border-box;
    border-radius: 20px;
    position: relative;
    text-align: center;
}

.login_container section:nth-of-type(1) {
    width: 50%;
}

.login_gray-circle {
    width: 280px;
    height: 280px;
    border-radius: 100%;
    background-color: #F9F9F9;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login_gray-circle img {
    width: 70%;
    height: 45%;
}

.login_right-container {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login_right-container img {
    width: 40%;
    margin-bottom: 20px;
    /*margin-top: 10px;*/
}

.fine_print {
    position: absolute;
    bottom: 5px;
    font-size: 12px;
    font-weight: lighter;
    color: lightslategray;
}

.login_text {
    font-weight: lighter;
    color: lightslategray;
    font-size: 24px;
}

.login_input {
    display: flex;
    align-items: center;
    color: lightslategray;
    font-size: 12px;
    margin-bottom: 3[x];
}

.login_input p {
    font-weight: bold;
    color: lightslategray;
    font-size: 10px;
}

.login_form section {
    margin-top: 20px;
}

.login_form button {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    height: 30px;
    background-color: #98002E;
    color: white;
    border: none;
    border-radius: 15px;
    font-size: 14px;
    cursor: pointer;
    text-align: center;
}