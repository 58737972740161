.auth_wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
}

.auth_login-text {
    font-weight: lighter;
    color: darkslategray;
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 24px;
}