@import 'react-date-range/dist/styles.css';
@import 'react-date-range/dist/theme/default.css';

body {
  font-family: 'SF UI Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  background-color: rgb(239, 239, 239);
}

.css-dfu2ko {
  display: flex;
}

.recharts-default-legend {
  text-transform: capitalize;
}
