.dashboard_wrapper {
    min-width: 1000px;
    max-width: 1600px;
    padding: 20px;
    box-sizing: border-box;
    width: 100%;
}

.dashboard_wrapper > section {
    display: flex;
    justify-content: space-between;
}

.survey_wrapper {
    width: 100%;
    background-color: white;
    padding: 15px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.top_store_icon {
    animation: spin 5s ease-in-out infinite;
}

@keyframes spin {
    from {transform:rotate(0deg);}
    75% { transform:rotate(360deg);}
    to {transform:rotate(360deg);}
}

.rank_container {
    animation: fadeIn 1.5s ease;
}

@keyframes fadeIn {
    from {opacity: 0;}
    to { opacity: 1;}
}