.surveylist_wrapper {
    padding: 20px;
    box-sizing: border-box;
    height: 100%;
}

.surveylist_container {
    display: flex;
    flex-wrap: wrap;
}

.surveylist_card {
    width: 400px;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.10), 0 3px 6px rgba(0,0,0,0.10);
    margin-right: 20px;
    margin-bottom: 20px;
}

.surveylist_card img {
    height: 50px;
    margin-bottom: 10px;
}

.surveylist_card-divider {
    width: 80%;
    height: 1px;
    background-color: lightgray;
    margin-top: 20px;
}

.surveylist_group {
    display: flex;
    margin-top: 20px;
    width: 90%;
}