.header_wrapper {
  width: 100%;
  height: 80px;
  background-color: white;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px 0px 20px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.1);
  z-index: 2;
  box-sizing: border-box;
}

.tog_logo {
  /*height: 30%;*/
  width: 80px;
}

.header_left-container {
  margin-left: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header_right-container {
  display: flex;
  align-items: center;
}

.current_survey_text {
  font-weight: bold;
  color: lightslategray;
  font-size: 12px;
  padding-bottom: 5px;
}

.close_icon:hover {
  transition: all 0.5s ease;
  transform: scale(1.3);
}

.notification_link {
  color: darkslategray;
  transition: all 0.2s ease;
  margin-bottom: 12px;
  padding: 10px;
  box-sizing: border-box;
}
.notification_link:hover {
  transform: scale(1.04);
}

.notification_link div:nth-child(1) {
  display: flex;
  align-items: center;
}

.notification_link p {
  font-size: 12px;
  font-weight: lighter;
}

.new_text {
  margin-left: 20px;
  margin-right: 10px;
  opacity: 0.5;
}

.no_notifications {
  width: 200px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.no_notifications p {
  margin-top: 8px;
  font-weight: lighter;
  color: lightslategray;
}

.notifications_header {
  font-weight: bold;
  color: lightslategray;
  padding: 8px;
  margin: 0 10px;
  width: 95%;
  border-bottom: 1px solid lightgray;
}

.notification_bell {
  animation: ring 7s ease infinite;
}

.notification_bell_none {
  animation: none;
  transform: rotate(0deg);
}

@keyframes ring {
  0% {
    transform: rotate(0deg);
  }
  15% {
    transform: rotate(0deg);
  }
  20% {
    transform: rotate(20deg);
  }
  25% {
    transform: rotate(-20deg);
  }
  30% {
    transform: rotate(20deg);
  }
  35% {
    transform: rotate(-10deg);
  }
  40% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
