.upload_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.upload_wrapper > section {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 50px;
}

.upload_wrapper img {
  margin-left: 30px;
  width: 300px;
}

.upload_test_button {
  text-align: center;
}

.drag_and_drop_container {
  transition: all 0.3s ease;
}
.drag_and_drop_container:hover {
  transform: scale(1.3);
}


#outter_loading {
  width: 300px;
  height: 10px;
  background: #f1f1f1;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  overflow: hidden;
  display: none;
  margin-top: 20px;
}

#inner_loading {
  background-color: indianred;
  width: 0%;
  height: 100%;
}

@keyframes load {
  0% {
    width: 0;
    background: #a28089;
  }

  50% {
    width: 15%;
  }

  100% {
    width: 50%;
    background: #ffa8b6;
  }
}

@keyframes load2 {
    0% {
      width: 50%;
      background: #ffa8b6;
    }
  
    50% {
      width: 70%;
      background: cornflowerblue;
    }
  
    100% {
      width: 100%;
      background: cornflowerblue;
    }
  }
