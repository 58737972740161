.navmenu_wrapper {
    width: 200px;
    height: 100%;
    background-color: white;
    padding: 110px 20px 20px 20px;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    position: fixed;
    box-sizing: border-box;
    left: 0;
    z-index: 1;
}

.navmenu_wrapper a { text-decoration: none; }

.sidenav_text { transition: all 0.1s linear; cursor: pointer; }