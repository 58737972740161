.sidemenu_locations {
  transition: all 0.2s ease;
  cursor: default;
  padding: 20px;
  box-sizing: border-box;
}

.sidemenu_locations:hover {
  background-color: #e18a24;
  color: white;
  font-weight: bolder;
}

.sidemenu_locations_selected {
  background-color: #e18a24;
  color: white;
  font-weight: bolder;
  padding: 20px;
  box-sizing: border-box;
}

.sidemenu_locations_header {
  font-size: 16px;
  padding-bottom: 5px;
}

.sidemenu_locations_header span:nth-child(1) {
  float: right;
  right: 10;
  font-weight: lighter;
  font-style: italic;
  font-size: 13px;
  text-align: right;
}

/* Date Range Side Menu */
.sidemenu_daterange_container section:nth-child(2) {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;
}

.sidemenu_daterange_container section:nth-child(3) {
  padding: 40px;
}

.sidemenu_date_range_info p {
  line-height: 1.3;
  font-weight: bold;
  color: darkslategray;
}

.sidemenu_date_range_info h3 {
  margin-bottom: 15px;
  color: rgb(35 67 97);
  font-weight: normal;
}

/* Presets Side Menu */
.sidemenu_presets {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  box-sizing: border-box;
  transition: all 0.2s ease;
  cursor: default;
}

.sidemenu_presets:hover {
  background-color: #0e77c9;
  color: white;
  font-weight: bolder;
}

.sidemenu_presets:hover .preset_edit_icon {
  fill: white !important;
  cursor: pointer;
}

.sidemenu_presets_selected {
  background-color: #0e77c9;
  color: white;
  font-weight: bolder;
}

.sidemenu_presets_selected .preset_edit_icon {
  fill: white !important;
  cursor: pointer;
}

.sidemenu_presets_location_container {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.locations_button {
  background-color: orange !important;
  border-color: orange !important;
  color: white !important;
  border: none !important;
}

.locations_button:hover {
  background-color: #dc8f00 !important;
  border-color: none !important;
  border: none !important;
}

.responses_button {
  background-color: indianred !important;
  border-color: indianred !important;
  color: white !important;
  border: none !important;
}
.responses_button:hover {
   background-color: #a44141 !important;
   border-color: none !important;
   border: none !important;
}

.sidemenu_responses {
  transition: all 0.2s ease;
  cursor: default;
  padding: 20px;
  box-sizing: border-box;
  color: darkslategray;
}
.sidemenu_responses:hover {
  background-color: indianred;
  color: white;
  font-weight: bolder;
}
.sidemenu_responses_selected {
  background-color: indianred;
  color: white;
  font-weight: bolder;
  padding: 20px;
  box-sizing: border-box;
}
.sidemenu_responses_header {
  font-size: 16px;
  padding-bottom: 5px;
  text-transform: capitalize;
  color: inherit;
}
.sidemenu_responses_header span:nth-child(1) {
  float: right;
  right: 10;
  font-weight: lighter;
  font-style: italic;
  font-size: 13px;
  text-align: right;
}