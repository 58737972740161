.printing_wrapper {
  position: absolute;
  min-height: 100%;
  top: 0px;
  left: 0px;
  background-color: white;
  width: 10in;
  margin: 0px;
  padding: 0px;
}

.printing_header {
  left: 0;
  top: 0;
  display: flex;
  padding-left: 5px;
  padding-right: 5px;
}

.printing_header h1 {
  font-size: 16px;
  color: darkslategray;
  width: 33%;
}

.printing_header h1:nth-child(2) {
  text-align: center;
}
.printing_header h1:nth-child(3) {
  text-align: right;
}

.printing_info {
  position: fixed;
  width: 20vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  right: 5px;
  top: 5px;
}

.printing_info > div {
  text-align:center;
}

.printing_info h1 {
  color: darkslategray;
  text-align: center;
  font-size: 26px;
}

.printing_info h2 {
  color: lightslategray;
  text-align: center;
  font-size: 18px;
  font-weight: normal;
}

.printing_info h3 {
  font-weight: normal;
  color: darkslategray;
}

.printing_info p {
  margin-top: 20px;
  text-decoration: underline;
  cursor: pointer;
}

.printing_info_box {
  text-align: center;
  cursor: pointer;
  border: 1px solid lightslategray;
  padding: 40px 20px;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: whitesmoke;
}

.printing_info_box:hover {
  transform: scale(1.03);
  transition: all 0.3s ease;
}

.bob {
  animation: bob 2s infinite ease-in-out;
  color: darkslategray;
}

@keyframes bob {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

@media print {
  .printing_info {
    display: none;
  }
  .comments_box_scroll {
    overflow-y: hidden !important;
  }
  body {
    background-color: white !important;
  }
}